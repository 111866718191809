import React from "react"
import {  Link } from "gatsby"
import { css } from "@emotion/core"
import { Container } from "react-bootstrap"

export default value => {


  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <Container>
        <div
          css={css`
            width: 90%;
            margin: 0 auto;
          `}
        >
          <div
          css={css`
            height: 1px;
            background-color: #dadbdb;
            margin: 6px auto 9px;
          `}
        />
          <div
            css={css`
              width: 100%;
              margin: 0 auto;
            `}
          >
            <Link
              css={css`
                display: flex;
                cursor: pointer;
                color: #000000;
                text-decoration: none;
              `}
              to="/about/#contactUs"
            >
              <div
                css={css`
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 28px;
                  margin: 0 auto;
                `}
              >
                联系我们
              </div>
            </Link>
          </div>
        </div>
        <div
          css={css`
            width: 90%;
            height: 1px;
            background-color: #dadbdb;
            margin: 6px auto;
          `}
        />
        <div
          css={css`
            width: 90%;
            margin: 0 auto;
          `}
        >
          <div
            css={css`
              width: 100%;
              margin: 0 auto;
            `}
          >
            <Link
              css={css`
                display: flex;
                cursor: pointer;
                color: #000000;
                text-decoration: none;
              `}
              to="/policy"
            >
              <div
                css={css`
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 28px;
                  margin: 0 auto;
                `}
              >
                隐私政策
              </div>
            </Link>
          </div>
        </div>
        <div
          css={css`
            width: 90%;
            height: 1px;
            background-color: #dadbdb;
            margin: 6px auto;
          `}
        />{" "}
        <div
          css={css`
            width: 90%;
            margin: 0 auto;
          `}
        >
          <div
            css={css`
              width: 100%;
              margin: 0 auto;
            `}
          >
            <Link
              css={css`
                display: flex;
                cursor: pointer;
                color: #000000;
                text-decoration: none;
              `}
              to="/agreement"
            >
              <div
                css={css`
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 28px;
                  margin: 0 auto;
                `}
              >
                服务条款
              </div>
            </Link>
          </div>
        </div>
        <div
          css={css`
            width: 90%;
            height: 1px;
            background-color: #dadbdb;
            margin: 6px auto;
          `}
        />
         <div
          css={css`
            width: 90%;
            margin: 0 auto;
          `}
        >
          <div
            css={css`
              width: 100%;
              margin: 0 auto;
            `}
          >
            <Link
              css={css`
                display: flex;
                cursor: pointer;
                color: #000000;
                text-decoration: none;
              `}
              to="/service-level-agreements"
            >
              <div
                css={css`
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 28px;
                  margin: 0 auto;
                `}
              >
                SLA服务
              </div>
            </Link>
          </div>
        </div>
        <div
          css={css`
            width: 90%;
            height: 1px;
            background-color: #dadbdb;
            margin: 6px auto;
          `}
        />
      </Container>
    </div>
  )
}
