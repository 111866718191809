import React from 'react'
import { css } from '@emotion/core'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import MobileFooter from './mobileFooter'
/* eslint no-unused-vars: 0 */
import style from './styles.css'

export default () => (
  <div>
    <div className="webFooter">
      <Container>
        <Row>
          <Col xl="12" lg="12" md="12" sm="12">
            <div
              css={css`
                font-size: 20px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: rgba(102, 102, 102, 1);
                line-height: 28px;
              `}
            >
              福州无锋网络科技有限责任公司
            </div>
            <div
              css={css`
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(151, 152, 153, 1);
                line-height: 20px;
                margin-top: 24px;
              `}
            >
              地址：福州仓山区金山大道橘园洲工业园区悦孚办公园区38号楼3楼307
              <br />
              邮箱：bd@x-grit.com
            </div>
          </Col>
          <Col
            xl="2"
            lg="2"
            md="2"
            sm="2"
            css={css`
              padding-left: 1%;
            `}
          >
            <Link
              to="/about/"
              css={css`
                text-decoration: none;
              `}
            >
              <div
                css={css`
                  font-size: 16px;
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: rgba(51, 51, 51, 1);
                  line-height: 22px;
                `}
              >
                关于我们
              </div>
            </Link>
            <div
              css={css`
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(173, 170, 174, 1);
                line-height: 36px;
                margin-top: 22px;
              `}
            >
              <Link
                to="/about#intro"
                css={css`
                  text-decoration: none;
                  color: rgba(173, 170, 174, 1);
                `}
              >
                <div>公司简介</div>
              </Link>
              <Link
                to="/about#culture"
                css={css`
                  text-decoration: none;
                  color: rgba(173, 170, 174, 1);
                `}
              >
                <div>企业文化</div>
              </Link>
              <Link
                to="/about#history"
                css={css`
                  text-decoration: none;
                  color: rgba(173, 170, 174, 1);
                `}
              >
                <div>发展历史</div>
              </Link>
              <Link
                to="/about#contactUs"
                css={css`
                  text-decoration: none;
                  color: rgba(173, 170, 174, 1);
                `}
              >
                <div>联系我们</div>
              </Link>
            </div>
          </Col>
          <Col
            xl="5"
            lg="5"
            md="5"
            sm="5"
            css={css`
              padding-left: 4.5%;
            `}
          >
            <Link
              to="/operation/"
              state={{ option: 1 }}
              css={css`
                text-decoration: none;
              `}
            >
              <div
                css={css`
                  font-size: 16px;
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: rgba(51, 51, 51, 1);
                  line-height: 22px;
                `}
              >
                核心业务
              </div>
            </Link>
            <div
              css={css`
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(173, 170, 174, 1);
                line-height: 36px;
                margin-top: 22px;
              `}
            >
              <Link
                to="/operation/"
                state={{ option: 1 }}
                css={css`
                  text-decoration: none;
                  color: rgba(173, 170, 174, 1);
                `}
              >
                <div>软件系统开发</div>
              </Link>
              <Link
                to="/operation/"
                state={{ option: 2 }}
                css={css`
                  text-decoration: none;
                  color: rgba(173, 170, 174, 1);
                `}
              >
                <div>数据采集和分析</div>
              </Link>
              <Link
                to="/operation/"
                state={{ option: 3 }}
                css={css`
                  text-decoration: none;
                  color: rgba(173, 170, 174, 1);
                `}
              >
                <div>短信和银行卡身份验证</div>
              </Link>
            </div>
          </Col>

          <Col
            xl="2"
            lg="2"
            md="2"
            sm="2"
            css={css`
              padding-left: 1%;
            `}
          >
            <Link
              to="/recruit/"
              css={css`
                text-decoration: none;
              `}
            >
              <div
                css={css`
                  font-size: 16px;
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: rgba(51, 51, 51, 1);
                  line-height: 22px;
                `}
              >
                人才招聘
              </div>
            </Link>
            <div
              css={css`
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(173, 170, 174, 1);
                line-height: 36px;
                margin-top: 22px;
              `}
            >
              <Link
                to="/recruit#positionRequires"
                css={css`
                  text-decoration: none;
                  color: rgba(173, 170, 174, 1);
                `}
              >
                <div>职位招聘</div>
              </Link>
            </div>
          </Col>
          <Col
            xl="3"
            lg="3"
            md="3"
            sm="3"
            css={css`
              padding-left: 4%;
            `}
          >
            <Link
              to="/recruit/"
              css={css`
                text-decoration: none;
              `}
            >
              <div
                css={css`
                  font-size: 16px;
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: rgba(51, 51, 51, 1);
                  line-height: 22px;
                `}
              >
                政策协议
              </div>
            </Link>
            <div
              css={css`
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(173, 170, 174, 1);
                line-height: 36px;
                margin-top: 22px;
              `}
            >
              <Link
                to="/agreement"
                css={css`
                  text-decoration: none;
                  color: rgba(173, 170, 174, 1);
                `}
              >
                <div>服务协议</div>
              </Link>
              <Link
                to="/policy"
                css={css`
                  text-decoration: none;
                  color: rgba(173, 170, 174, 1);
                `}
              >
                {' '}
                <div>隐私政策</div>
              </Link>
              <Link
                to="/service-level-agreements"
                css={css`
                  text-decoration: none;
                  color: rgba(173, 170, 174, 1);
                `}
              >
                {' '}
                <div>SLA服务</div>
              </Link>
            </div>
          </Col>
        </Row>
        <div
          css={css`
            width: 100%;
            height: 1px;
            background-color: #e3e7ee;
            margin-top: 110px;
          `}
        />
        <div
          css={css`
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(167, 165, 167, 1);
            line-height: 20px;
            padding: 24px 0;
          `}
        >
          Copyright © 2014 - 2020 All Rights Reserved
          福州无锋网络科技有限责任公司 版权所有<Link
              to="https://beian.miit.gov.cn"
              css={css`
                color: rgba(167,165,167,1);
              `}
            >闽ICP备17028199号-1</Link>
        </div>
      </Container>
    </div>
    <div className="mobileFoot">
      <MobileFooter />
      <Container>
        <div
          css={css`
            width: 90%;
            font-family: PingFang-SC-Bold, PingFang-SC;
            margin: 0 auto;
            margin-top: 30px;
          `}
        >
          <div
            css={css`
              font-size: 18px;
              color: rgba(147, 147, 147, 1);
              line-height: 36px;
            `}
          >
            地址
          </div>
          <div
            css={css`
              font-size: 15px;
              line-height: 20px;
              font-weight: bold;
            `}
          >
            福州仓山区金山大道橘园洲工业园区悦孚办公园区38号楼3楼307
          </div>
          <div
            css={css`
              font-size: 18px;
              color: rgba(147, 147, 147, 1);
              line-height: 36px;
            `}
          >
            邮箱
          </div>
          <div
            css={css`
              font-size: 15px;
              line-height: 20px;
              font-weight: bold;
            `}
          >
            bd@x-grit.com
          </div>
          <div
            css={css`
              font-size: 10px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(167, 165, 167, 1);
              line-height: 14px;
              margin: 35px 0px;
            `}
          >
            Copyright © 2013 - 2020 All Rights Reserved
            <br />
            福州无锋网络科技有限责任公司 版权所有  <Link
              to="https://beian.miit.gov.cn"
              css={css`
                color: rgba(167,165,167,1);
              `}
            >闽ICP备17028199号-1</Link>
          </div>
        </div>
      </Container>
    </div>
  </div>
)
